<template>
  <div>
    <base-header base-title="Sms History"></base-header>
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                :table-name="'table_sms_history'"
                :on-refresh="fetch"
              ></auto-refresh>&nbsp;&nbsp;
            </div>
          </div>
        </div>
        <div
          class="kt-portlet__body"
        >
          <!-- <data-tables :table="table"></data-tables> -->
          <v-server-table  
              :columns="columns"
              :options="options" 
              ref="table"
              @loaded="onLoaded(0)"
              @loading="onLoaded(1)"
          >
              <div slot="action" slot-scope="{ row }">
                  <btn-action 
                    :rowData="row"
                    :storeObject="$store"></btn-action>
              </div>
              <div v-if="isLoading" slot="afterBody" >
                  <h6 class="txt"> loading..</h6>
              </div>
              <div slot="created_at" slot-scope="{ row }">
                  <span class="p-2">{{row.created_at | changeTime}}</span> <br/>
              </div>
              <div slot="updated_at" slot-scope="{ row }">
                  <span class="p-2">{{row.updated_at | changeTime}}</span> <br/>
              </div>
          </v-server-table>
        </div>
        <!-- <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div> -->
        <form-modal
          v-if="isModalSMS"
        ></form-modal>
      </div>
    </div>
  </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "../../ecommerce/repositories/BaseRepositoryFactory";
const MasterRepository = RepositoryFactory.get("HistoryNotification");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader,
    BtnAction,
    BaseButtonModal,
    FormModal: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(import(/* webpackChunkName: "modal-sms-configuration" */ "./_components/FormModal.vue"));
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      columns: ['id','created_at','updated_at','transactionId','recipient','message', 'sendStatus', 'deliveryStatus', 'action'],
      options: {
                texts: {
                    filterBy: 'Cari',
                },
                filterable: ['id','created_at','updated_at','transactionId','recipient','message', 'sendStatus', 'deliveryStatus'],
                headings: {
                    id: "ID",
                    created_at: "Tgl Buat",
                    updated_at: "Tgl Update",
                    transactionId: "Id Transaksi",
                    recipient: "Penerima",
                    message: "Pesan",
                    sendStatus: "Send Status",
                    deliveryStatus: "Delivery Status",
                    action: 'Actions'
                },
                dateColumns: ['created_at', 'updated_at' ],
                debounce: 500,
                 filterByColumn: true,
                  listColumns: {
                    sendStatus: [{
                            id: 'SUCCESS',
                            text: 'SUCCESS'
                        },
                        {
                            id: 'INVALID_PARAM',
                            text: 'INVALID_PARAM'
                        },
                        {
                            id: 'INTERNAL_ERROR',
                            text: 'INTERNAL_ERROR'
                        },
                        {
                            id: 'INVALID_DESTINATION',
                            text: 'INVALID_DESTINATION'
                        },
                        {
                            id: 'IP_IS_NOT_WHITELIST',
                            text: 'IP_IS_NOT_WHITELIST'
                        },
                        {
                            id: 'MEMBERSHIP_IS_NOT_AVAILABLE',
                            text: 'MEMBERSHIP_IS_NOT_AVAILABLE'
                        },
                        {
                            id: 'NOT_ENOUGH_CREDIT',
                            text: 'NOT_ENOUGH_CREDIT'
                        },
                        {
                            id: 'INVALID_USER_PASSWORD',
                            text: 'INVALID_USER_PASSWORD'
                        },
                    ],
                    deliveryStatus: [{
                            id: 'DELIVERED',
                            text: 'DELIVERED'
                        },
                        {
                            id: 'UNDELIVERED',
                            text: 'UNDELIVERED'
                        },
                        {
                            id: 'PENDING',
                            text: 'PENDING'
                        },
                        {
                            id: 'UNSENT',
                            text: 'UNSENT'
                        },
                         {
                            id: 'FAILED',
                            text: 'FAILED'
                        },
                        {
                            id: 'UNKNOWN_STATUS',
                            text: 'UNKNOWN_STATUS'
                        },
                    ]
                },
                toMomentFormat: 'YYYY-MM-DD',
                requestFunction(data) {
                    let params = {};
                    let page = {};
                    page.sort= 'created_at,desc'
                    page.page = data.page-1;
                    page.size = data.limit;
                    this.isLoaded = false
                    if(_.isEmpty(data.query) && localStorage.login){
                        data.query = { 'login': localStorage.login }
                        // setTimes
                    } else {
                        localStorage.removeItem('login')
                    }
                    if(_.isEmpty(data.query)){
                        vx.modeSearch = false;
                        return  MasterRepository.fetchSMS(page).catch(function (e) {
                            this.dispatch('error', e);
                        });
                    }else{
                        vx.modeSearch = true;
                        params.query = helpers.filterSMSTable(data.query);
                        return MasterRepository.searchSMS(params.query, page).catch(function (e) {
                            vx.showToastError('Error Get Data');
                            this.dispatch('error', e);
                        });
                    }
                },
                responseAdapter: (resp) => {  
                    return {
                    data: resp.data,
                    count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                 }
                },
                rowClassCallback(row) {
                  return  (row.isNewData) ? 'highlight':'';
                },
                filterByColumn: true,
                sortable:[],
                templates: {
                    // created_at(h, row) {
                    //     return moment(row.created_at).add(7, "hours").format("DD-MMM-YYYY");
                    // },
                    // authorities(h, row) {
                    //     let text = '<span class="p-2 mt-1 badge badge-info">'+row.authorities+'</span> <br/>'
                    //     return row.authorities
                    // },
                    // activated(h, row) {
                    //     return row.activated == true ? 'True' : 'False'
                    // },
                }
            },
            modeSearch: true,
            isLoading: false,
      headTitle: "Sms History",
    };
  },
  computed: {
    ...mapState("NotifikasiStore", {
      isModalSMS: state => state.isModalSMS
    }),
    ...mapState('MasterStore', {
      repository: state => state.repository,
      isLoaded: state => state.isLoaded,
      table: state => state.table,
      isShowModal: state => state.isShowModal,
      dataEdit: state => state.dataEdit,
      modalName: state => state.modalName
    }),
  },
  watch: {
    isShowModal: function (val) {
      if (!val) {
        this.setEditData(null);
      }
    }
  },
  filters: {
    changeTime(val) {
      if(val) {
        return moment(val).format("DD-MMM-YYYY HH:mm:ss")
      }
      return '-'
    }
  },
  methods: {
    ...mapActions({
      fetch: 'MasterStore/fetch'
    }),
    ...mapMutations({
      setupTable: 'MasterStore/SETUP_TABLE',
      showModal: 'MasterStore/SHOW_MODAL',
      setRepository: 'MasterStore/SET_REPOSITORY',
      setModal: 'MasterStore/SET_MODAL',
      setEditData: 'MasterStore/SET_EDIT_DATA',
      setLoaded: 'MasterStore/SET_LOADED'
    }),
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    fetch: function(resolve) {
        this.$refs.table.getData();
        if(resolve != undefined){
          resolve();
        }
    },
    onLoaded: function(t) {
      t == 0 ? this.isLoading = false : this.isLoading = true  
    },
    showToastError: function(message) {
      toastr.error(message);
    }
  },
  created() {
    this.setLoaded(false);
    this.setModal('modal_sms_history');
  },
};
</script>
