<template>
    <div>
      <base-header base-title="Email History"></base-header>
      <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__head kt-portlet__head--lg">
            <base-title :head-title="headTitle"></base-title>
            <div class="kt-portlet__head-toolbar">
              <div class="kt-portlet__head-wrapper"></div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <!-- <data-tables :table="table"></data-tables> -->
            <v-server-table
              :columns="columns"
              :options="options"
              ref="table"
              @loaded="onLoaded(0)"
              @loading="onLoaded(1)"
            >
              <div slot="action" slot-scope="{ row }">
                <button
                  @click="showData(row)"
                  class="btn btn-sm btn-clean btn-icon btn-icon-md"
                  title="view"
                >
                  <i class="la la-eye"></i>
                </button>
              </div>
              <div v-if="isLoading" slot="afterBody">
                <h6 class="txt">loading..</h6>
              </div>
              <div slot="created_at" slot-scope="{ row }">
                <span class="p-2">{{ row.created_at | changeTime }}</span> <br />
              </div>
              <div slot="updated_at" slot-scope="{ row }">
                <span class="p-2">{{ row.updated_at | changeTime }}</span>
                <br />
              </div>
            </v-server-table>
          </div>
          <!-- <div
            v-else
            class="m-auto p-5 d-flex flex-row h-100"
          >
            <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
            <h5 class="my-auto pl-5">Loading ...</h5>
          </div> -->
            <template v-if="isModalEmail">
              <form-modal :id-email="idSelected"></form-modal>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BaseTitle from "./../_base/BaseTitle";
  import BaseHeader from "./../_base/BaseHeader";
  import LoadSpinner from "./../_general/LoadSpinner";
  import { RepositoryFactory } from "../../ecommerce/repositories/BaseRepositoryFactory";
  const MasterRepository = RepositoryFactory.get("HistoryNotification");
  import { mapState, mapMutations } from "vuex";
  
  export default {
    components: {
      BaseTitle,
      BaseHeader,
      FormModal: () => ({
        component: new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve(
              import(
                /* webpackChunkName: "modal-sms-configuration" */ "./_components/FormModal.vue"
              )
            );
          }, 1000);
        }),
        loading: LoadSpinner,
      }),
    },
    data() {
      var vx = this;
      return {
        columns: [
          "id",
          "created_at",
          "updated_at",
          "sender",
          "subject",
          "deliveryStatus",
          "emailTemplateId",
          "sendStatus",
          "recipient",
          "action",
        ],
        options: {
          texts: {
            filterBy: "Cari",
          },
          filterable: [
            "id",
            "created_at",
            "updated_at",
            "sender",
            "subject",
            "deliveryStatus",
            "emailTemplateId",
            "sendStatus",
            "recipient",
          ],
          headings: {
            id: "ID",
            created_at: "Tgl Buat",
            updated_at: "Tgl Update",
            sender: "Pengirim",
            recipient: "Penerima",
            emailTemplateId: "Template Id",
            sendStatus: "Send Status",
            deliveryStatus: "Delivery Status",
            subject: "Subject",
            action: "Actions",
          },
          dateColumns: ["created_at", "updated_at"],
          debounce: 500,
          filterByColumn: true,
          toMomentFormat: "YYYY-MM-DD",
          requestFunction(data) {
            let params = {};
            params.sort = "created_at,desc";
            params.page = data.page - 1;
            params.size = data.limit;
             if(data.orderBy) {
                let orderBy = data.orderBy
                params.sort = `${orderBy},${
                data.ascending == "1" ? "desc" : "asc"
              }`;
            }
            if (_.isEmpty(data.query)) {
              return MasterRepository.fetchEmail(params).catch(function (e) {
                vx.showToastError("Error Get Data");
              });
            } else {
              params.query = helpers.filterSMSTable(data.query);
              return MasterRepository.searchEmail(params.query, {page: params.page, size: params.size, sort: params.sort}).catch(
                function (e) {
                  vx.showToastError("Error Get Data");
                }
              );
            }
          },
          responseAdapter: (resp) => {
            return {
              data: resp.data,
              count: resp.headers["x-total-count"]
                ? resp.headers["x-total-count"]
                : resp.headers["Content-Length"]
                ? resp.headers["Content-Length"]
                : resp.data.length,
            };
          },
          rowClassCallback(row) {
            return row.isNewData ? "highlight" : "";
          },
          filterByColumn: true,
          sortIcon: {
            base: "fa",
            is: "fa-sort",
            up: "fa-sort-up",
            down: "fa-sort-down ",
          },
          sortable: ["id",
            "created_at",
            "updated_at",
            "sender",
            "subject",
            "deliveryStatus",
            "emailTemplateId",
            "sendStatus",
            "recipient",],
          templates: {},
        },
        isLoading: false,
        headTitle: "Email History",
        idSelected: "",
      };
    },
    destroyed() {
      this.changeShowModal(false);
    },
    computed: {
      ...mapState("NotifikasiStore", {
        isModalEmail: (state) => state.isModalEmail,
      }),
    },
    filters: {
      changeTime(val) {
        if (val) {
          return moment(val).format("DD-MMM-YYYY HH:mm:ss");
        }
        return "-";
      },
    },
    methods: {
      ...mapMutations({
        changeShowModal: "NotifikasiStore/SET_MODAL_EMAIL",
      }),
      onLoaded: function (t) {
        t == 0 ? (this.isLoading = false) : (this.isLoading = true);
      },
      showToastError: function (message) {
        toastr.error(message);
      },
      async showData(item) {
        this.idSelected = item.id;
        this.changeShowModal(true);
      },
    },
    mounted() {
      this.changeShowModal(false);
    },
  };
  </script>
  