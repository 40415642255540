<template>
    <div>
      <base-header base-title="Whatsapp History"></base-header>
      <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__head kt-portlet__head--lg">
            <base-title :head-title="headTitle"></base-title>
            <div class="kt-portlet__head-toolbar">
              <div class="kt-portlet__head-wrapper">
                <!-- <auto-refresh
                  :table-name="'table_whatsapp_history'"
                  :on-refresh="fetch"
                ></auto-refresh>&nbsp;&nbsp; -->
              </div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <!-- <data-tables :table="table"></data-tables> -->
            <v-server-table
              :columns="columns"
              :options="options"
              ref="table"
              @loaded="onLoaded(0)"
              @loading="onLoaded(1)"
            >
              <div slot="action" slot-scope="{ row }">
                <button
                  @click="showData(row)"
                  class="btn btn-sm btn-clean btn-icon btn-icon-md"
                  title="view"
                >
                  <i class="la la-eye"></i>
                </button>
              </div>
              <div v-if="isLoading" slot="afterBody">
                <h6 class="txt">loading..</h6>
              </div>
              <div slot="created_at" slot-scope="{ row }">
                <span class="p-2">{{ row.created_at | changeTime }}</span> <br />
              </div>
              <div slot="updated_at" slot-scope="{ row }">
                <span class="p-2">{{ row.updated_at | changeTime }}</span>
                <br />
              </div>
            </v-server-table>
          </div>
  
          <template v-if="isModalWhatsapp">
            <form-modal :id-whatsapp="idSelected"></form-modal>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BaseTitle from "./../_base/BaseTitle";
  import BaseHeader from "./../_base/BaseHeader";
  import LoadSpinner from "./../_general/LoadSpinner";
  import { RepositoryFactory } from "../../ecommerce/repositories/BaseRepositoryFactory";
  const MasterRepository = RepositoryFactory.get("HistoryNotification");
  import { mapState, mapMutations } from "vuex";
  
  export default {
    components: {
      BaseTitle,
      BaseHeader,
      FormModal: () => ({
        component: new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve(
              import(
                /* webpackChunkName: "modal-sms-configuration" */ "./_components/FormModal.vue"
              )
            );
          }, 1000);
        }),
        loading: LoadSpinner,
      }),
    },
    data() {
      var vx = this;
      return {
        idSelected: "",
        isShowData: false,
        detailWhatsapp: {},
        columns: [
          "id",
          "created_at",
          "updated_at",
          "whatsappTemplateId",
          "message",
          "recipient",
          "sendStatus",
          "deliveryStatus",
          "action",
        ],
        options: {
          texts: {
            filterBy: "Cari",
          },
          filterable: [
            "id",
            "created_at",
            "updated_at",
            "whatsappTemplateId",
            "recipient",
            "message",
            "sendStatus",
            "deliveryStatus",
          ],
          headings: {
            id: "ID",
            created_at: "Tgl Buat",
            updated_at: "Tgl Update",
            whatsappTemplateId: "Id Whatsapp",
            recipient: "Penerima",
            message: "Pesan",
            sendStatus: "Send Status",
            deliveryStatus: "Delivery Status",
            action: "Actions",
          },
          dateColumns: ["created_at", "updated_at"],
          debounce: 500,
          filterByColumn: true,
          toMomentFormat: "YYYY-MM-DD",
          requestFunction(data) {
            let params = {};
            let page = {};
            page.sort = "created_at,desc";
            page.page = data.page - 1;
            page.size = data.limit;
            this.isLoaded = false;
             if(data.orderBy) {
                let orderBy = data.orderBy
                page.sort = `${orderBy},${
                data.ascending == "1" ? "desc" : "asc"
              }`;
            }
            if (_.isEmpty(data.query)) {
              return MasterRepository.fetchWhatsapp(page).catch(function (e) {
                // this.dispatch('error', e);
                vx.showToastError("Error Get Data");
              });1
            } else {
              // params.query = helpers.filterWithKey(data.query);
              params.query = helpers.filterSMSTable(data.query);
              return MasterRepository.searchWhatsapp(params.query, page).catch(
                function (e) {
                  vx.showToastError("Error Get Data");
                  // this.dispatch('error', e);
                }
              );
            }
          },
          responseAdapter: (resp) => {
            return {
              data: resp.data,
              count: resp.headers["x-total-count"]
                ? resp.headers["x-total-count"]
                : resp.headers["Content-Length"]
                ? resp.headers["Content-Length"]
                : resp.data.length,
            };
          },
          rowClassCallback(row) {
            return row.isNewData ? "highlight" : "";
          },
          sortIcon: {
            base: "fa",
            is: "fa-sort",
            up: "fa-sort-up",
            down: "fa-sort-down ",
          },
          filterByColumn: true,
          sortable: ["id",
            "created_at",
            "updated_at",
            "whatsappTemplateId",
            "recipient",
            "message",
            "sendStatus",
            "deliveryStatus",],
          templates: {
            // created_at(h, row) {
            //     return moment(row.created_at).add(7, "hours").format("DD-MMM-YYYY");
            // },
            // authorities(h, row) {
            //     let text = '<span class="p-2 mt-1 badge badge-info">'+row.authorities+'</span> <br/>'
            //     return row.authorities
            // },
            // activated(h, row) {
            //     return row.activated == true ? 'True' : 'False'
            // },
          },
        },
        isLoading: false,
        headTitle: "Whatsapp History",
      };
    },
     destroyed(){
        this.changeShowModal(false)
    },
    computed: {
      ...mapState("MasterStore", {
        isLoaded: (state) => state.isLoaded,
      }),
      ...mapState("NotifikasiStore", {
        isModalWhatsapp: (state) => state.isModalWhatsapp,
      }),
    },
    filters: {
      changeTime(val) {
        if (val) {
          return moment(val).format("DD-MMM-YYYY HH:mm:ss");
        }
        return "-";
      },
    },
    methods: {
      ...mapMutations({
        changeShowModal: "NotifikasiStore/SET_MODAL_WHATSAPP",
      }),
  
      onLoaded: function (t) {
        t == 0 ? (this.isLoading = false) : (this.isLoading = true);
      },
      showToastError: function (message) {
        toastr.error(message);
      },
      async showData(item) {
        // console.log('cekk', item)
        this.idSelected = item.id;
        this.changeShowModal(true);
      },
    },
    mounted() {
      this.changeShowModal(false);
    },
  };
  </script>
  